<template>
    <div class="cs-multi-column-text cs-block" :class="styleClass">
        <div class="cs-block-base" :class="rootClasses">
            <div class="container">
                <div class="header-content" v-if="data.subTitle || data.title">
                    <h2 class="cs-title" v-if="data.title">{{data.title}}</h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle" v-html="data.subTitle"></h3>
                </div>
                <div class="row justify-content-center">
                    <div
                        :class="changeColumns"
                        v-for="(item, key) in data.textList"
                        :key="key"
                    >
                        <h4 v-if="item.title">{{ item.title }}</h4>
                        <span class="cs-text" v-html="item.text"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
    name: 'MulticolumnText',
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    components: {},
    data() {
        return {}
    },
    computed: computed('MulticolumnText')
}
</script>
<style lang="scss" scoped>
@import '../styles/main.scss';
.cs-multi-column-text {
    &.cs-style-dark {
        h4 {
            color: #fff !important;
        }
    }

    .header-content {
        margin-bottom: 10px;
    }
    .cs-block-base {
        h4 {
            @include small-title;
            margin-bottom: 15px;
        }
    }
}
</style>
